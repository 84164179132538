import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const DocsPage = () => (
  <Layout>
    <SEO title="Docs" />
    <div>
      <div className="page-header">
        <Container>
          <h1 style={{ padding: "40px" }}>
            Product documentation
          </h1>
        </Container>
      </div>
    </div>
    <div style={{ padding: "50px 10%", textAlign: "center", minHeight: "60vh" }}>
      <Container style={{ maxWidth: "800px", paddingTop: "50px" }}>
        <p style={{ marginBottom: "60px" }}>
          Follow the links below for product documentation
        </p>
        <Row style={{ maxWidth: "600px", margin: "auto" }}>
          <Col xs={6}>
            <div>
              <h4>Canvas</h4>
              <p>OpenADR Server (VTN)</p>
              <Button
                href="https://canvas-docs.gridfabric.io"
                target="_blank"
                className="btn-gridfabric-outline"
              >
                Documentation
              </Button>
              <p><i>in progress</i></p>
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <h4>Plaid</h4>
              <p>OpenADR Client (VEN)</p>
              <Button
                href="https://plaid-docs.gridfabric.io"
                target="_blank"
                className="btn-gridfabric-outline"
              >
                Documentation
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default DocsPage
